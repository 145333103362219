import ApiService from "@/core/services/ApiService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import type {LessonData} from "@/types/LessonType";


@Module
export default class LessonModule extends VuexModule {
    errors: {} = {};
    lessonData: LessonData = {} as LessonData;
    currentLesson: LessonData = {} as LessonData;

    /**
     * Get current user object
     * @returns LessonData
     */
    get getCurrentLesson(): LessonData {
        return this.currentLesson;
    }

    get getLessonData(): LessonData {
        return this.lessonData;
    }

    @Action
    async [Actions.GET_LESSON_ALL]() {

        try {
            const response: any = await ApiService.get("/academy?");
            this.context.commit(Mutations.SET_LESSON_DATA, response.data);
        } catch (error) {
            this.context.commit(Mutations.SET_LESSON_DATA, {});
            this.errors = error;
            
        }
    }

    @Action
    async [Actions.GET_LESSON](id: number) {
        try {
            const response: any = await ApiService.get('/academy/lessons/'+id);
            this.context.commit(Mutations.SET_LESSON_DATA, response.data.data);
        } catch (error) {
            this.context.commit(Mutations.SET_LESSON_DATA, {});
            this.errors = error;
           
        }
    }
    @Action
    async [Actions.UPDATE_WATCHED_CHECK](id: number) {
        try {
            const response: any = await ApiService.put(`/academy/lessons/${id['id']}/check`, {});
            this.context.commit(Mutations.SET_CURRENT_LESSON_DATA, response.data.data);
        } catch (error) {
            this.context.commit(Mutations.SET_CURRENT_LESSON_DATA, {});
            this.errors = error;
           
        }
    }

    @Mutation
    [Mutations.SET_LESSON_DATA](lessonData: LessonData) {
        if(lessonData.lesson) {
            this.lessonData.lesson = lessonData.lesson;
        }
        this.lessonData = lessonData;
    }

    @Mutation
    [Mutations.SET_CURRENT_LESSON_DATA](lesson: LessonData) {
        this.currentLesson = lesson;
    }

    @Mutation
    [Mutations.SET_LESSON_WATCHED_CHECK](lesson: LessonData) {
        this.currentLesson = lesson;
    }
}