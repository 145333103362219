import {Action, Module, Mutation, VuexModule} from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import {AxiosResponse} from "axios";
import ApiService from "@/core/services/ApiService";
import type {ScoreSystemType} from "@/types/ScoreSystemType";

@Module
export default class ScoreSystemModule extends VuexModule {
    errors = {};
    scoreSystemData = {} as ScoreSystemType;
    currentScoreSystem = {} as ScoreSystemType;

    get getCurrentScoreSystem() {
        return this.currentScoreSystem;
    }

    get getScoreSystemData(){
        return this.scoreSystemData;
    }

    @Action
    async [Actions.GET_SCORE_SYSTEM](id: string) {
        try {
            const response: AxiosResponse<any> = await ApiService.get(`/club/scoreboard/${id}`);
            //console.log(response.data);
            this.context.commit(Mutations.SET_CURRENT_SCORE_SYSTEM_DATA, response.data);
        } catch (error) {
            this.context.commit(Mutations.SET_CURRENT_SCORE_SYSTEM_DATA, {});
        }
    }

    @Mutation
    [Mutations.SET_SCORE_SYSTEM_DATA](scoreSystem: ScoreSystemType) {
    
        this.scoreSystemData = scoreSystem;
    }

    @Mutation
    [Mutations.SET_CURRENT_SCORE_SYSTEM_DATA](scoreSystem: ScoreSystemType) {
        console.log('X: SET CURRENT SCORE SYSTEM DATA', scoreSystem)

        this.currentScoreSystem = scoreSystem;
    }
}