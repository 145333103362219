import { createApp } from "vue";
import App from "./App.vue";

/*
TIP: To get started with clean router change path to @/router/clean.ts.
 */
import router from "./router";
import store from "./store";
import ElementPlus from "element-plus";
// @ts-ignore
import { setupI18n } from './i18n';
// @ts-ignore
import en from './locales/languages.json';

//imports for app initialization
import ApiService from "@/core/services/ApiService";
import { initApexCharts } from "@/core/plugins/apexcharts";
import { initInlineSvg } from "@/core/plugins/inline-svg";
import { initVeeValidate } from "@/core/plugins/vee-validate";

import * as Sentry from "@sentry/vue";
import {BrowserTracing} from '@sentry/tracing';
import "@/core/plugins/prismjs";
import "bootstrap";

import 'video.js/dist/video-js.css';
// City
import '@videojs/themes/dist/city/index.css';
// Forest
import '@videojs/themes/dist/forest/index.css';


import Maska from 'maska'

import moment from 'moment'
import 'moment/locale/pt-br'  // without this line it didn't work
moment.locale('pt-br')
import  momentDurationFormatSetup from "moment-duration-format";
momentDurationFormatSetup(moment);


const app = createApp(App);

if(process.env.NODE_ENV === 'production') {
  Sentry.init({
    environment: process.env.NODE_ENV,
    app,
    dsn: "https://08ecdaff89804ae7bb2bfe44355da8c8@o1097234.ingest.sentry.io/6270135",
    trackComponents: true,
    normalizeDepth: 21,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}
const i18n = setupI18n({
  legacy: false,
  globalInjection: true,
  locale: 'en',
  fallbackLocale: 'en',
  messages: {
    en,
  },
});

app.use(store);
app.use(router);
app.use(ElementPlus);
app.use(Maska);

ApiService.init(app);
initApexCharts(app);
initInlineSvg(app);
initVeeValidate();

app.use(i18n);

app.mount("#app");
